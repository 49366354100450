import React, { Component, useEffect, useRef, useState } from 'react'
import { graphql, Link } from 'gatsby'

import {
  useWindowSize,
  useWindowWidth,
  useWindowHeight,
} from '@react-hook/window-size'

import SEO from '../components/seo'
import Layout from '../components/layout'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import styled from 'styled-components'
import {
  FacebookShareButton,
  TwitterShareButton,
  EmailShareButton,
  LinkedinShareButton,
} from 'react-share'
import { Facebook, Twitter, Mail, Linkedin } from 'react-feather'

import ContactForm from '../components/estimate-form'

import { ReviewForInModal } from '../components/reviews-all-modal-popup'
import PhotoGalleryComponent from '../components/photo-gallery-component'

import ReactCompareImage from '../components/before-after-image'
import { TestimonialVideo } from '../components/testimonial_video'

import Breadcrumbs from '../components/breadcrumbs'

import '../styles/projects-gallery.scss'

import Carousel, { Modal, ModalGateway } from 'react-images'
import Slider from 'react-slick'

import MagicSliderDots from 'react-magic-slider-dots'
import 'react-magic-slider-dots/dist/magic-dots.css'

import {
  SliderContainer,
  PrevArrow,
  NextArrow,
} from '../components/slider-components.js'
import { Maximize } from 'react-feather'

import { RelatedBlogPosts } from '../components/RelatedBlogPosts'

const ContactCard = styled.div`
  top: 30px !important;
  background: rgb(249 249 249);
`

const BlogPostsLayoutContainer = styled.div`
  display: flex;
  padding: 2em 1em;
  flex-wrap: wrap;
`

const Heading = styled.div`
  background: #fff;

  padding: 3em 1em;
  padding-bottom: 5em;
  text-align: center;
  margin-bottom: -5em;
  h1 {
    font-size: calc(1.15vw + 1.25em);
    margin-bottom: 0.15em;
  }
  p {
    color: #666;
    margin-bottom: 0.15em;
  }
`

const BlogPostLayout = styled.div`
  background: #fff;
  .articleBody {
    max-width: 900px;
    margin: 0 auto;
    padding: 1em;
  }
`
const Header = styled.div`
  text-align: left;
  margin: 0 auto;
  margin-bottom: 1em;
  border-bottom: 1px solid #eaeaea;
  h1 {
    font-size: calc(1.15vw + 1.25em);
    line-height: 1.15;
    font-weight: 800;
    color: #000;
  }
  border-bottom: 1px solid #f1f1f1;
  background: #f7f7f7;
`

const GatsbyImageWrapper = styled(GatsbyImage)`
  flex: 0 0 12.5%;
  padding: 7px;

  box-shadow: 0 6px 12px -2px rgba(50, 50, 93, 0.25);
  border-radius: 2px;
  &:hover {
    box-shadow: 0 7px 15px -2px rgba(50, 50, 93, 0.3);
  }
  @media (max-width: 992px) {
    flex: 0 0 33.333333333%;
  }
  @media (max-width: 767px) {
    flex: 0 0 50%;
  }
  img {
    margin: 0;
  }
`

const BlogPostImage = styled.div`
  img {
    max-width: 900px;
    margn: 0 auto;
    text-align: center;
    border-radius: 0;
    margin-bottom: 1em;
    box-shadow: none !important;
  }
`
const ShareThisPost = styled.div`
  p {
    font-weight: bold;
    margin: 0;
  }
  display: flex;
  align-items: center;
  .SocialMediaShareButton {
    display: flex;
    padding: 0 9px;
  }
  svg {
    stroke: none;
    fill: #888;
    cursor: pointer;
  }
  .stroke-white {
    stroke: white;
  }
`

const ImageGalleryImage = ({
  fluid,
  fullScreen,
  i,
  setMaxHeight,
  maxHeight,
  windowHeight,
  windowWidth,
  isFullScreen,
  toggleLightbox,
  pagePath,
  postTitle,
  previews,
  ...gatsbyImageData
  // activeImageState,
}) => {
  return (
    <div className="relative" onClick={() => toggleLightbox(i)}>
      <GatsbyImageWrapper
        // ref={gatsbyImgRef}
        alt={postTitle}
        title={postTitle}
        className="image-gallery-image overflow-hidden mb-0 cursor-pointer"
        image={isFullScreen ? fullScreen : getImage(previews)}
      />
    </div>
  )
}

const settings = {
  accessibility: true,
  dots: true,
  infinite: true,
  // autoplay: true,
  autoplaySpeed: 7000,
  pauseOnHover: false,
  // speed: 300,
  slidesToShow: 1,
  slidesToScroll: 1,
  swipeToSlide: true,
  fade: false,
  cssEase: 'linear',
  className: 'disable-interaction-on-non-active relative',
  nextArrow: <PrevArrow />,
  prevArrow: <NextArrow />,
  appendDots: dots => {
    return <MagicSliderDots dots={dots} numDotsToShow={6} dotWidth={30} />
  },
}

const ProjectPage = props => {
  const [lightboxIsOpen, setLightboxIsOpen] = useState(false)
  // const activeImageState = useState(
  //   props.data.contentfulProjects.photoGallery.photos.map((_, i) => [i, false])
  // )
  // const [
  //   activeImageLoadingState,
  //   updateActiveImageLoadingState,
  // ] = activeImageState
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [isFullScreen, setFullScreen] = useState(false)
  const [maxHeight, setMaxHeight] = useState(0)
  const [windowWidth, windowHeight] = useWindowSize({ wait: 100 })

  const toggleLightbox = selectedIndex => {
    setLightboxIsOpen(!lightboxIsOpen)
    setSelectedIndex(selectedIndex)
  }

  const { isLoading } = props

  const post = props.data.contentfulProjects
  const siteurl = props.data.site.siteMetadata.siteUrl
  const relatedPosts = []

  const { moreInCategory } = props.data

  const images = post.photoGallery.photos.map(
    (
      {
        id,
        fluid,
        fixed,
        fullScreen,
        fullscreen,
        title,
        gatsbyImageData,
        previews,
        ...props
      },
      i
    ) => {
      return {
        i,
        id: i,
        caption: 'Solid Construction & Design',
        src: gatsbyImageData.images.fallback.src,
        w: 1200,
        h: 900,
        alt: title,
        thumbnail: previews.src,
        original: fullscreen.images.fallback.src,
        fullScreen,
        fixed,
        fluid,
        previews,
        gatsbyImageData,
      }
    }
  )
  const items = images
  // const showImageLoader = !activeImageLoadingState[selectedIndex][1]

  return (
    <Layout pageProps={props}>
      <div class="bg-white border-b border-gray-100">
        <nav class="flex container mx-auto py-3" aria-label="Breadcrumb">
          <Breadcrumbs
            links={[
              { to: '/', label: 'Home' },
              { to: '/projects/', label: 'Projects' },
              {
                to: props.location.pathname,
                label: `${post.title} in ${post.city}, ${post.zipCode}`,
              },
            ]}
          />
        </nav>
      </div>
      <SEO
        title={
          post.metaTitle
            ? post.metaTitle
            : post.title + ' in ' + post.city + ', CA ' + post.zipCode
        }
        description={
          post.metaDescription
            ? post.metaDescription
            : 'View Project Photos of A Gorgeous ' +
              post.title +
              ' In ' +
              post.city +
              ' CA, ' +
              post.zipCode +
              ' Designed & Built By Solid Construction and Design.'
        }
        img={post.heroImage.gatsbyImageData.images.fallback.src}
        twitterCardContent={'summary_large_image'}
      />
      <BlogPostLayout className="relative">
        <article itemScope itemType="https://schema.org/Article">
          <div
            itemProp="publisher"
            itemScope
            itemType="https://schema.org/Organization"
            className="hidden"
          >
            <span itemProp="name" className="hidden">
              Solid Construction and Design
            </span>
            <span
              itemProp="image"
              itemScope
              itemType="http://schema.org/ImageObject"
              className="hidden"
            >
              <img
                src="https://images.ctfassets.net/555y3d6wo2js/687cWoNXYdUvjoPIKLH6ko/513b022817506fe1203cbb6dd98a885e/solid-thumb.jpg"
                className="hidden"
                width="262"
                itemProp="url"
              />
            </span>
            <span
              itemProp="logo"
              itemScope
              itemType="http://schema.org/ImageObject"
              className="hidden"
            >
              <img
                src="https://images.ctfassets.net/555y3d6wo2js/687cWoNXYdUvjoPIKLH6ko/513b022817506fe1203cbb6dd98a885e/solid-thumb.jpg"
                className="hidden"
                width="262"
                itemProp="url"
              />
            </span>
          </div>
          <Header>
            <div className="lg:flex container lg:row mx-auto">
              <div
                className={`lg:container py-12 text-left blogimgmw w-full lg:w-2/3 relative ${
                  isFullScreen ? 'z-20000' : 'z-20'
                }`}
              >
                <h1 itemProp="name headline" className="text-left -mt-3 mb-4">
                  {post.title} in {post.city}, {post.zipCode}
                </h1>
                {post.description && (
                  <p
                    className="text-left text-base text-gray-700 mb-6"
                    dangerouslySetInnerHTML={{
                      __html: post.description.description,
                    }}
                  />
                )}

                <div
                  className="mx-auto relative z-20"
                  style={{ marginBottom: '-150px' }}
                >
                  <div className="grid grid-cols-3 gap-4">
                    {images.map(props => (
                      <ImageGalleryImage
                        itemProp="image"
                        itemScope
                        itemType="http://schema.org/ImageObject"
                        maxHeight={maxHeight}
                        windowHeight={windowHeight}
                        windowWidth={windowWidth}
                        pagePath={props.path}
                        toggleLightbox={toggleLightbox}
                        isFullScreen={isFullScreen}
                        setMaxHeight={setMaxHeight}
                        postTitle={post.title}
                        // activeImageState={activeImageState}
                        {...props}
                      />
                    ))}
                  </div>
                  <div className="flex flex-1 items-stretch px-2 md:px-6">
                    {/* <ShareThisPost className="bg-white pr-3 justify-content-start p-1 flex-1">
                      <p className="hidden md:block text-base tracking-widest uppercase">
                        Share —{' '}
                      </p>
                      <FacebookShareButton
                        url={siteurl + '/projects/' + post.slug}
                        quote={post.title}
                        hashtag={'#solidconstructiondesign'}
                        className="bg-white hover:bg-gray-200 cursor-pointer flex h-8 items-center p-1 rounded-full w-8 mr-1"
                      >
                        <Facebook size={20} />
                      </FacebookShareButton>

                      <TwitterShareButton
                        url={siteurl + '/projects/' + post.slug}
                        title={post.title}
                        hashtags={post.tags}
                        className="bg-white hover:bg-gray-200 cursor-pointer flex h-8 items-center p-1 rounded-full w-8 mr-1"
                      >
                        <Twitter size={20} />
                      </TwitterShareButton>
                      <LinkedinShareButton
                        url={siteurl + '/projects/' + post.slug}
                        title={post.title}
                        quote={post.title}
                        hashtags={post.tags}
                        className="bg-white hover:bg-gray-200 cursor-pointer flex h-8 items-center p-1 rounded-full w-8 mr-1"
                      >
                        <Linkedin size={20} />
                      </LinkedinShareButton>
                      <EmailShareButton
                        subject={post.title}
                        url={siteurl + '/projects/' + post.slug}
                        openWindow={true}
                        body={'Check out this article: '}
                        className="bg-white hover:bg-gray-200 cursor-pointer flex h-8 items-center p-1 rounded-full w-8 mr-1"
                      >
                        <Mail size={20} className="stroke-white" />
                      </EmailShareButton>
                    </ShareThisPost> */}
                    <ModalGateway>
                      {lightboxIsOpen ? (
                        <Modal onClose={() => toggleLightbox(selectedIndex)}>
                          <Carousel
                            currentIndex={selectedIndex}
                            frameProps={{ autoSize: '100vh' }}
                            styles={{
                              view: (base, state) => ({
                                ...base,
                                width: 'auto',
                              }),
                            }}
                            views={items}
                          />
                        </Modal>
                      ) : null}
                    </ModalGateway>
                  </div>
                </div>
              </div>
              <div className="w-full lg:w-1/3"></div>
            </div>
          </Header>
          <div className="lg:flex max-w-screen-xl lg:row mx-auto">
            <div
              className="relative pt-2 w-full lg:w-2/3 z-10"
              style={{ marginTop: '60px' }}
            >
              {/* <div
              style={{ marginTop: '110px' }}
              itemProp="articleBody"
              dangerouslySetInnerHTML={{ __html: post.body.body }}
            />
            <p>
              Tags:{' '}
              {post.tags.map((item, i) => (
                <span itemProp="keywords" key={i}>
                  {' ' + item},
                </span>
              ))}
            </p> */}
            </div>
            <div className="w-full lg:w-1/3"></div>
            <div className="max-w-screen-xl block lg:flex lg:row w-full h-full top-0 justify-end relative lg:absolute">
              <div className="w-full lg:w-2/3 invisible" />
              <div className="container w-full lg:w-1/3 py-12 relative justify-self-end p-4 lg:p-6 pt-14">
                {post && post.videoTestimonial && (
                  <>
                    <h3 className="text-center mb-0 mt-3">
                      Watch Video Testimonial
                    </h3>
                    <div className="py-8 md:pb-6 pt-3 self-center">
                      <TestimonialVideo
                        id={0}
                        thumbnail={post.videoTestimonial.thumbnail}
                        video={post.videoTestimonial.video}
                        className="w-full"
                        rounded
                        projectsPage
                      />
                    </div>
                  </>
                )}
                <ContactCard className="border-radius p-6 cardborder shadow-lg sticky top-0">
                  <span className="block text-center text-base font-semibold mb-1">
                    Need Help With A Similar Project?
                  </span>
                  <h3 className="text-center">Request a FREE Estimate!</h3>
                  <div className="mb-6 sidebarform">
                    <ContactForm
                      showLabels
                      showPlaceHolders
                      pageLocation={
                        props.location.href
                          ? props.location.href
                          : 'Not provided'
                      }
                    />
                  </div>
                </ContactCard>
              </div>
            </div>
          </div>
        </article>
      </BlogPostLayout>
      {moreInCategory.edges.length > 0 && (
        <div className="container mx-auto p-4 py-8">
          <h3>See Other {post.categories[0].name} Projects</h3>
          <div className="grid grid-cols-2 gap-4 lg:grid-cols-4">
            {moreInCategory &&
              moreInCategory.edges &&
              moreInCategory.edges.map((item, i) => (
                <Link
                  key={i}
                  to={`/projects/${item.node.slug}/`}
                  className="bg-white border-radius shadow hover:shadow-lg"
                >
                  <GatsbyImage
                    image={getImage(item.node.heroImage)}
                    style={{ height: '150px' }}
                    alt={item.node.title}
                    className="border-radius-top"
                  />
                  <div className="p-3 py-4 pt-3">
                    <span className="text-gray-700 text-base">
                      {item.node.publishDate}
                    </span>
                    <span className=" font-semibold leading-tight text-gray-900 block">
                      {item.node.title}
                    </span>
                    <div className="flex items-center text-gray-800 text-xs mt-1">
                      <div className="">
                        <img
                          src="/assets/location-pin.svg"
                          className="w-2 mb-0"
                        />
                      </div>
                      <div className="ml-2 text-xs">
                        {item.node.city}, CA {item.node.zipCode}
                      </div>
                    </div>
                  </div>
                </Link>
              ))}
          </div>
        </div>
      )}
      <RelatedBlogPosts props={props} data={props.data.relatedBlogPosts} />
    </Layout>
  )
}

export const pageQuery = graphql`
  query ProjectPage(
    $slug: String!
    $categoryId: [String]
    $categorySlugExp: String
  ) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    contentfulProjects(slug: { eq: $slug }) {
      id
      title
      slug
      categories: category {
        id
        name
        slug
      }
      date
      city
      zipCode
       thumbnail {
        gatsbyImageData(width: 1500, quality: 70)
      }
      heroImage: thumbnail {
        gatsbyImageData(width: 1500, quality: 70)
        metaTagImage: gatsbyImageData(width: 1200, height: 630)
      }
      photoGallery {
        id
        photos {
          id
          previews: gatsbyImageData(
            height: 252
            width: 302
            quality: 80
            aspectRatio: 1
            cropFocus: CENTER
            resizingBehavior: FILL
          )
          gatsbyImageData(width: 1800, quality: 80)
          fullscreen: gatsbyImageData(width: 2000, quality: 80)
        }
      }
      description {
        id
        description
      }
      videoTestimonial {
        id
        thumbnail {
          gatsbyImageData(width: 1000, quality: 70, placeholder: NONE)
        }
        video {
          file {
            url
            contentType
          }
        }
      }
    }
    moreInCategory: allContentfulProjects(
      filter: {
        category: { elemMatch: { id: { in: $categoryId } } }
        slug: { ne: $slug }
      }
      limit: 4
      sort: { order: DESC, fields: date }
    ) {
      edges {
        node {
          id
          title
          slug
          date
          city
          zipCode
          heroImage: thumbnail {
            gatsbyImageData(width: 470, height: 247)
          }
        }
      }
    }
    relatedBlogPosts: allContentfulBlogPost(
      filter: { category: { slug: { regex: $categorySlugExp } } }
      limit: 4
      skip: 6
      sort: { fields: publishDate, order: DESC }
    ) {
      edges {
        node {
          id
          title
          slug
          category {
            id
            slug
            name
          }
          publishDate(formatString: "MMMM Do, YYYY")
          heroImage {
            gatsbyImageData(width: 1200, quality: 70, placeholder: NONE)
          }
          author {
            name
          }
        }
      }
    }
  }
`

export default ProjectPage
