import React from 'react'

import { Link, graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

export const RelatedBlogPosts = ({ data: relatedBlogPosts = [] }) => {
  console.log('relatedBlogPosts', relatedBlogPosts)
  return (
    <div className="bg-white">
      <div className="container mx-auto p-4 py-8">
        <h3>From The Blog</h3>
        <div className="grid grid-cols-2 gap-4 lg:grid-cols-4">
          {relatedBlogPosts &&
            relatedBlogPosts.edges &&
            relatedBlogPosts.edges.map((item, i) => (
              <Link
                key={i}
                to={`/blog/${item.node.slug}`}
                className="bg-white border-radius shadow hover:shadow-lg"
              >
                <GatsbyImage
                  image={getImage(item.node.heroImage)}
                  style={{ height: '150px' }}
                  title={item.node.title}
                  alt={item.node.title}
                  className="border-radius-top"
                />
                <div className="p-3 py-4 pt-3">
                  <span className=" font-semibold leading-tight text-gray-900 block">
                    {item.node.title}
                  </span>
                  <div className="flex items-center text-gray-800 text-xs mt-1">
                    {item.node.publishDate} by {item.node.author.name}
                  </div>
                </div>
              </Link>
            ))}
        </div>
      </div>
    </div>
  )
}
